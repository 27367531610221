import React from 'react';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const CustomInputField = props => {

	const validateInput = values => {
        if (values.some(f => f === "") || values[0].indexOf("@") === -1) {
            return true
        } else {
            return false
        }
    }

    if (props.type === "submit") {
        return (
			<Button
                className='!mt-4'
                type='submit'
				variant="contained"
                value={props.label}
                disabled={validateInput(props.formValues)}
				endIcon={<SendIcon />}
            >Send
			</Button>
        )
    } else if (props.type === "textarea") {
        return (
			<TextField
				onChange={(e) => props.onChangeHandler(e)}
				placeholder={props.placeholder}
				value={props.value}
				required={props.isRequired}
				className="w-full"
				label={props.label}
				multiline
				error = {props.error}
				margin="normal"
				variant="standard"
				rows={2}
				name={props.name}
				helperText={props.helperText}
			/>
        );
    } else if (props.type === "number") {
        return (
			<TextField
				onChange={(e) => props.onChangeHandler(e)}
				placeholder={props.placeholder}
				value={props.value}
				required={props.isRequired}
				className="w-full"
				error = {props.error}
				inputProps={{ maxLength: 10 }}
				label={props.label}
				margin="normal"
				variant="standard"
				name={props.name}
				helperText={props.helperText}
			/>
        );
    } else {
        return (
			<TextField
				onChange={(e) => props.onChangeHandler(e)}
				type={props.type}
				placeholder={props.placeholder}
				value={props.value}
				required={props.isRequired}
				margin="normal"
				error = {props.error}
				variant="standard"
				className="w-full"
				label={props.label}
				name={props.name}
				helperText={props.helperText}
			/>
        );
    }

};

export default React.memo(CustomInputField);