import { createSlice } from '@reduxjs/toolkit';

export const navSlice = createSlice({
	name: 'navState',
	initialState: {
		navState: 'Home',
	},
	reducers: {
		changeNavState: {
			reducer: (state, action) => {
				state.navState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeNavState } = navSlice.actions

export default navSlice.reducer